
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import UserStore from '@/modules/user/store/user.store';

@Component({
    components: {
        LoaderWrapper,
        CustomLoader,
    },
})
export default class AuthPage extends Vue {
    @Inject(AuthServiceS) private authService!: AuthService;
    @Inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    @Prop({ required: true })
    private token!: string;

    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');

    beforeMount() {
        this.otelService.startSpan({ name: 'login' });
    }

    async mounted() {
        if (this.token) {
            try {
                await this.authService.authentication(this.token);
                this.otelService.endSpan({ name: 'login' }, { sendLogs: true });
                this.$router.push('/');
            } catch (error) {
                window.location.href = this.authService.loginUrl;
            }
        } else {
            window.location.href = this.authService.loginUrl;
        }
    }

    get isCarPage() {
        return /cr/.test(window.location.hostname);
    }
}
